<template>
  <div class="content-success-lilo">
    <div class="content-success-lilo__wrap">
      <ImageLazy
        :src="`/images/success-register.svg`"
        alt="success"
        width="190"
        height="190"
      />
      <p class="content-success-lilo__wrap__title">
        Pertanyaan Anda Telah Terkirim
      </p>
      <p class="content-success-lilo__wrap__desc">
        Tim kami akan menghubungi Anda melalui email, silahkan cek email Anda secara berkala
      </p>
    </div>
    <div class="content-success-lilo__button">
      <Button
        text="Ke Halaman Utama"
        type-class="outline-red"
        @click="Home"
      />
    </div>
  </div>
</template>
<script>
import Button from '@/components/new-button/Button.vue';
import ImageLazy from "@/components/ImageLazy/index.vue";
import { mapGetters } from "vuex";

export default {
  components: { Button, ImageLazy },
  data() {
    return {}
  },
  computed: {    
    ...mapGetters(["BUSINESS_INQUIRY_ID"]),
  },
  beforeMount() {
    this.inquiryId = this.BUSINESS_INQUIRY_ID;
    this.checkIdInquiry();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    checkIdInquiry() {
      if (this.inquiryId === null) {
        this.$router.push("/lilo");
      }
      else {
        this.$gtag.event("conversion", {
          send_to: "AW-376237548/IOUrCIj-io0CEOzbs7MB",
        });
      }
    },
    Home() {
        this.$router.push("/");
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.content-success-lilo {
    margin-bottom: 60px;
    margin-top: 100px;

    &__wrap {
        display: flex;
        align-items: center;
        flex-direction: column;

        &__title {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            margin-top: 20px;
            text-align: center;
            color: $color-base-text;
        }

        &__desc {
            width: 500px;
            font-size: 14px;
            font-weight: 400;
            margin-top: 10px;
            line-height: 21px;
            text-align: center;
            color: $color-gray-shade;

            @include for-size(mobile) {
                width: 80%;
            }
        }
    }

    &__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 24px;
        gap: 16px;
    }
}
</style>
